@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");

$background: #181523;

/* Style Colors */

$primary-color: #ffffff;
$secondary-color: #bcb9d0;
$third-color: #827f93;

$input-background: rgba(52, 46, 75, 0.6);

// Home Website
$primary-gray: #7e7996;
$secondary-gray: #968fae;
