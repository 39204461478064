@import "../../assets/styles/config.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: white;
  background: #0f0c1b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  -ms-user-select: none;
}

a {
  cursor: pointer;
}

.menu > a {
  padding: 10px 0;
}

.logo-img {
  width: 150px;
}

.nav-container {
  position: relative;
  width: 100%;
}

.nav-modal {
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100vh;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  background: linear-gradient(rgba(0, 0, 0, 0.75), black);
  backdrop-filter: blur(10px);
  z-index: 2;
}

.main-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.nav-wrapper {
  position: relative;
  max-width: 110rem;
  margin: 0 auto;
}

.nav-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 3rem;
}

.nav--items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav--items-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  display: none;
}

.dropdown-nav {
  /* first, make our dropdown cover the screen */
  position: fixed;
  top: 800px;
  left: 0;
  width: 100%;
  /* fix nav height on mobile safari, where 100vh is a little off */
  height: 100vh;
  /* then, arrange our links top to bottom */
  display: flex;
  flex-direction: column;
  /* center links vertically, push to the right horizontally.
       this means our links will line up with the rightward hamburger button */
  justify-content: center;

  /* add margins and padding to taste */
  margin: 0;
  padding-left: 7vw;
  padding-right: 7vw;
}

.mobile-dropdown-toggle {
  z-index: 999;
  background: transparent;
  border: none;
  overflow: hidden;
  color: white;
}

.dropdown-nav {
  /* our initial state */
  transform: translateY(-100%); /* move out of view */
  transition: transform 0.2s, opacity 0.2s; /* transition these smoothly */
}

nav.dropdown-opened > .dropdown-nav {
  opacity: 1; /* fade in */
  transform: translateY(0); /* move into view */
}

.nav-item {
  padding: 20px;
}

.nav-item,
.nav-login > a {
  font-weight: 600;
  font-size: 1.0625rem;
}

.nav--items-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.visible {
  display: none;
}

.nav-login {
  cursor: pointer;
  background: linear-gradient(270deg, #5965ee 0%, hsl(223, 75%, 64%) 100%);
  border-radius: 10px;
  padding: 10px 15px;
}

.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

#nav-logo {
  margin: 0 auto;
  margin-bottom: 20px;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: url("../../assets/images/hero-bg.png");
  -webkit-transform: skewY(-5deg);
  transform: skewY(-5deg);
  -webkit-transform-origin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -120px;
  z-index: -1;
}

.hero-wrapper {
  display: flex;
  flex-direction: column;
}

.container {
  display: block;
  position: relative;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}

.row {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}

.row-policy {
  display: flex;
  flex-direction: column;
}

.fixed-b {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.cookie-wrapper {
  width: 100%;
  background: rgba(23, 21, 31, 0.5);
  backdrop-filter: blur(10px);
}

.cookie-inner {
  padding: 20px 0;
  margin-left: 15px;
  margin-right: 15px;
}

.policy-title {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 5px;
}

.policy-description {
  font-size: 14px;
  font-weight: 400;
}

.spacing {
  padding-top: 75px;
}

.cookie-buttons {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.cookie-btn {
  display: flex;
  color: white;
  cursor: pointer;
  border: none;
  padding: 12px 15px;
  border-radius: 5px;
}

.accept {
  background: #5258ff;
}

.learn-more {
  background: #2e2c3f;
  margin-left: 10px;
}

.inner-container {
  padding: 0 10px;
  width: 100%;
}

.hero-text {
  display: flex;
  flex-direction: column;
  max-width: 450px;
}

.hero-title {
  font-size: 3.125rem;
}

.blue--accent {
  font-size: 3.125rem;
  color: #94adff;
}

.hero-image {
  display: block;
  width: 100%;
  padding: 75px 20px 0;
}

.hero-image img {
  max-width: 100%;
}

.hero-caption {
  font-size: 1.125rem;
  color: $primary-gray;
  padding-top: 10px;
}

.btn-dual-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem;
  padding-top: 1.875rem;
  align-items: center;
}

.apply {
  display: block;
  text-align: center;
  width: 100%;
  padding: 15px 40px;
  background: linear-gradient(270deg, #575ced 0%, rgba(133, 92, 231, 1) 100%);
  border-radius: 10px;
  font-weight: 600;
  transition: all 0.1s ease-in;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.learn {
  display: none;
  padding: 15px 30px;
  background: #242136;
  border-radius: 10px;
  transition: all 0.1s ease-in;

  &:hover {
    opacity: 0.8;
  }
}

//media queries

@media only screen and (min-width: 768px) {
  .btn-dual-container {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 0.625rem;
    padding-top: 1.875rem;
    align-items: center;
  }

  .learn {
    display: block;
  }

  .cookie-inner {
    padding: 20px 40px;
  }

  .visible {
    display: flex;
  }

  .mobile-dropdown-toggle {
    display: none;
  }

  .hero-container {
    height: 100vh;
  }

  .container {
    max-width: 800px;
  }

  .hero-wrapper {
    display: flex;
    flex-direction: row;
  }

  .hero-image img {
    width: 80%;
    padding: 80px 20px 0;
  }

  .hero-image {
    text-align: center;
  }

  .spacing {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 990px) {
  .hero-title {
    font-size: 4.125rem;
  }

  .blue--accent {
    font-size: 4.125rem;
  }

  .container {
    max-width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }

  .nav-container {
    max-width: 1240px;
    margin: 0 auto;
  }
}

// sitelist

.center {
  align-items: center;
}

.pad-top-m {
  padding-top: 235px;
}

.pad-top {
  padding-top: 150px;
}

.section-header {
  text-align: center;
}

.main-title {
  font-size: 32px;
  font-weight: 700;
}

.blue-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #94adff;
  letter-spacing: 0.2em;
}

.site-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  margin: 0 auto;
  row-gap: 15px;
  column-gap: 30px;
  padding-top: 40px;
}

.site-list svg {
  width: 55px;
  height: auto;
}

.row-site {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}

@media only screen and (min-width: 768px) {
  .main-title {
    font-size: 40px;
    font-weight: 700;
  }

  .site-list {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
    column-gap: 15px;
    padding-top: 0;
  }

  .section-header {
    text-align: start;
  }

  .row-site {
    flex-direction: row;
  }

  .pad-top {
    padding-top: 350px;
  }
}

//globe section

.grid-background {
  position: absolute;
  top: -450px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../assets/images/grid-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.globe-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.globe-img {
  width: 100%;
  margin: 0 auto;
}

.stats-container {
  position: relative;
  width: 100%;
}

.row-stats {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  text-align: center;
}

.stats-wrapper {
  background: linear-gradient(270deg, #212248 0%, rgb(55, 40, 94) 100%);
}

.stat-item {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.stat-header {
  font-size: 70px;
  font-weight: 700;
}

.stat-caption {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: $secondary-gray;
}

@media only screen and (min-width: 768px) {
  .globe-img {
    width: 35%;
    margin: 0 auto;
  }

  .row-stats {
    flex-direction: row;
    justify-content: center;
  }

  .grid-background {
    top: -100px;
  }

  .stat-item {
    padding: 30px 50px;
  }
}

// Client Dashboard Section

.client-box {
  position: relative;
  background: linear-gradient(270deg, #575ced 0%, rgba(133, 92, 231, 1) 100%);
  padding: 30px;
  border-radius: 1em 1em 0 0;
  margin-top: 30px;
  z-index: 1;
}

.circle-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../assets/images/circle.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.box-img {
  text-align: center;
  z-index: 999;
}

.client-img {
  width: 100%;
}

.box-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 30px;
}

.box-title {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 15px;
}

.box-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.trial-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #94adff;
  font-size: 20px;
  padding-top: 15px;
}

.feature--items {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 10px;
  padding-top: 10px;
  text-align: center;
}

.feature-item {
  display: flex;
  flex-direction: column;
  background: linear-gradient(200deg, #212248 0%, rgb(55, 40, 94) 100%);
  padding: 40px;
  align-items: center;
}

.feature-item svg {
  width: 35px;
  height: auto;
  margin-bottom: 15px;
}

.feature-title {
  font-size: 24px;
  font-weight: 700;
}

.feature-caption {
  color: #a59ebb;
  font-weight: 400;
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  .feature--items {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .box-header {
    padding-top: 50px;
  }

  .box-title {
    font-size: 40px;
  }

  .client-img {
    width: 50%;
  }
}

@media only screen and (min-width: 990px) {
  .feature--items {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

// partners
.partner-container {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.partner-box {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0 30px;
  border-radius: 1em;
  margin-top: 20px;
  text-align: center;
}

.partner-title {
  font-size: 18px;
  font-weight: 600;
}

.partner-heading {
  font-weight: 700;
  font-size: 30px;
}

.aio {
  background: linear-gradient(270deg, #5258ff 0%, rgb(135, 89, 242) 100%);
}

.partner-img img {
  width: 100%;
}

.z-links {
  background: linear-gradient(187deg, #25243d 0%, hsl(240, 25%, 12%) 100%);
}

.launchedx {
  background: linear-gradient(187deg, #6245ff 0%, rgba(76, 77, 255, 1) 100%);
}

@media only screen and (min-width: 768px) {
  .partner-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content min-content;
    column-gap: 20px;
  }

  .aio {
    display: grid;
    grid-column: 1 / 3;
  }

  .partner-title {
    font-size: 18px;
    font-weight: 600;
  }

  .partner-heading {
    font-weight: 700;
    font-size: 35px;
  }

  .partner-img img {
    width: 60%;
    margin: 0 auto;
  }
}

// join

.join-container {
  position: relative;
  width: 100%;
  background: #1a1727;
  margin-top: 175px;
  padding-top: 100px;
  background: url("../../assets/images/join-bg.png");
  background-repeat: no-repeat;
  background-position: 80% 10%;
  background-size: cover;
  height: 600px;
}

.join-wrapper {
  display: flex;
  flex-direction: column;
  padding: 150px 0;
}

@media only screen and (min-width: 768px) {
  .trial-link {
    justify-content: left;
  }
}

//container

.column {
  display: flex;
  flex-direction: row;
}

.footer-container {
  display: block;
  position: relative;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
  background: #1a1727;
}

.footer-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 2rem 2rem;
}

.menu {
  padding: 2rem 0;
}

.menu-items {
  display: flex;
  flex-direction: column;
}

.footer-inner-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 0;
}

.footer-title {
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 0.3em;
}

.footer-item {
  color: #a5a0c4;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0;
}

hr {
  display: block;
  height: 0.5px;
  border: 0;
  border-top: 0.5px solid $secondary-gray;
  margin: 0.7em 0;
  padding: 0;
}

.copyright {
  color: $secondary-gray;
}

.social-icon {
  color: $secondary-gray;
  padding: 0 5px;
}

@media only screen and (min-width: 768px) {
  .join-container {
    background-position: 10% 75%;
  }

  .footer-inner {
    flex-direction: row;
    text-align: left;
  }

  .footer-inner-top {
    flex-direction: row;
  }

  .menu {
    padding: 0 2rem;
  }

  .menu-items {
    flex-direction: row;
  }
}
