@import "../../assets/styles/config.scss";

/* Form Styles */
.hook-container {
  margin: 20px 0;
  padding: 0 40px;
}

.hook-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 10px;
  text-align: left;
}

.shopify-fields {
  display: flex;
  flex-direction: column;
}

input {
  display: block;
  margin: auto;
  width: 100%;
  text-align: center;
  background-color: $input-background;
  outline: none;
  border: none;
  backdrop-filter: blur(20px);
  border-radius: 10px;
  padding: 12px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

::placeholder {
  color: $secondary-color;
}
