//input styling
input {
  text-align: left;
  margin-top: 0.9375rem;
  background: #141123;
  border: 0.0625rem solid #3b355a;
  border-radius: 0.625rem;
  padding: 0.625rem;
}

// landing styling

.landing-hero {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #0f0c1b;
}

.landing-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("../../assets/images/apply-.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.landing-container {
  position: relative;
  min-height: 90vh;
  margin: 0 auto;
}

.apply-container {
  position: relative;
  margin: 0 auto;
  form {
    width: 100%;
    .card {
      margin-bottom: 8px;
      width: 100%;
      .radio-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 100%;
        label {
          min-width: 90%;
        }
        input {
          width: 100%;
        }
      }
    }
  }
}

//form styles

.form-outer {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 100%;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(90deg, rgba(152, 109, 196, 0.4) 0%, rgba(62, 136, 250, 0.4) 50%, rgba(96, 169, 255, 0.4) 100%);
  border: 0.0313rem solid #8d9aff;
  border-radius: 0.625rem;
}

.form-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0.9375rem;

  .apply-header {
    font-size: 2.5rem;
  }

  p {
    color: #e0e3ff;
    margin-top: 0.625rem;
    font-size: 1rem;
  }
}

// discord button
.btn,
.btn2 {
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
  height: 3rem;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 0.625rem;
  background-size: 600%;
  -webkit-animation: anime 16s linear infinite;
  animation: anime 16s linear infinite;
  border: none;
}

.btn2 {
  position: absolute;
  z-index: -1;
  -webkit-filter: blur(1.875rem);
  filter: blur(1.875rem);
  opacity: 0.8;
}

.continue {
  background: linear-gradient(-45deg, #3db1ff, #655bff, #338aff, #50e9fd);
}
.cta {
  margin-top: 1.5rem;
}
// Onboarding
.btn-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  .btn {
    width: 40%;
  }
  .logout {
    background-color: #0f0c1b;
  }
}

@-webkit-keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/*
FORM STYLES
*/

.form-header {
  font-size: 2.5rem;
}

.apply-split {
  display: grid;
  grid-template-columns: 1fr 25rem;
  grid-gap: 0.625rem;
  margin-top: 1.25rem;
}

// START OF LEFT COLUMN

.left-column {
  display: grid;
  grid-template-rows: repeat(12, min-content);
  grid-gap: 0.625rem;
}

.card {
  padding: 0.9375rem;
  background: #120f20;
  border: 0.0625rem solid #433d5d;
  border-radius: 0.625rem;

  .card-header {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.625rem;
    align-items: center;
  }

  .companion-header {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.625rem;
    align-items: center;
    margin-top: 0.625rem;
  }

  .card-title {
    font-weight: 600;
    font-size: 1.25rem;
  }

  .card-description {
    margin-top: 0.625rem;
    font-weight: 600;
    color: #e2e5ff;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .feature {
      margin: 0.5rem 0;
    }
  }

  .companion-content {
    display: grid;
    grid-template-rows: 1fr min-content min-content;
    grid-gap: 0.625rem;

    .companion-img {
      width: 100%;
      height: auto;
      border-radius: 0.625rem;
    }
  }

  img {
    width: 1.25rem;
    height: auto;
  }
}

.card-gradient {
  padding: 0.9375rem;
  background-image: linear-gradient(-46deg, rgba(152, 109, 196, 0.4) 0%, rgba(62, 136, 250, 0.4) 52%, rgba(96, 169, 255, 0.4) 100%);
  border: 0.0625rem solid #4b5494;
  border-radius: 0.625rem;

  .card-header {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.625rem;
    align-items: center;
  }

  .card-title {
    font-weight: 600;
    font-size: 1.25rem;
  }

  .card-description {
    margin-top: 0.625rem;
    font-weight: 600;
    color: #e6e8ff;
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  img {
    width: 1.25rem;
    height: auto;
  }
}

.contact-container {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-gap: 0.625rem;
}

.submit-btn {
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  width: 100%;
  background-image: linear-gradient(-46deg, #986dc4 0%, #3e88fa 52%, #60a9ff 100%);
  border: 0.0625rem solid #3e88fa;
  border-radius: 0.3125rem;
  outline: none;
  padding: 0.625rem 0;
  transition: 0.1s all ease-in;

  &:hover {
    opacity: 0.8;
  }
}

// END OF LEFT COLUMN

.right-column {
  display: block;
}

.summary-container {
  width: 100%;
  background-image: linear-gradient(-47deg, rgba(152, 109, 196, 0.4) 0%, rgba(62, 136, 250, 0.4) 50%, rgba(96, 169, 255, 0.4) 100%);
  border: 0.0313rem solid #8d9aff;
  border-radius: 0.625rem;
  padding: 0.5rem;

  .card-header {
    font-weight: 600;
  }
}

.profile-summary {
  background: rgba(25, 27, 44, 0.6);
  border: 0.0625rem solid #363e62;
  border-radius: 0.625rem;
  padding: 1.25rem;
  margin-top: 0.625rem;

  .profile-content {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.625rem;
    align-items: center;

    img {
      width: 3.75rem;
      height: auto;
    }

    p {
      max-width: 15.625rem;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.break-blue {
  width: 100%;
  border: 0.0313rem solid #626bb4;
  margin: 0.625rem 0;
}

.summary-title {
  font-weight: 600;
  font-size: 1rem;
}

.sitelist-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.625rem;
  margin-top: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;

  .sitelist-1 {
    display: flex;
    flex-direction: column;

    ul {
      list-style-type: none;
    }

    p {
      margin-top: 0.1875rem;
    }
  }

  .sitelist-2 {
    display: flex;
    flex-direction: column;

    ul {
      list-style-type: none;
    }

    p {
      margin-top: 0.1875rem;
    }
  }
}

.break-purple {
  width: 100%;
  border: 0.0313rem solid #75628d;
  margin: 0.9375rem 0;
}

.summary {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0.9375rem;
  margin-top: 1.25rem;

  img {
    width: 1.25rem;
    height: auto;
  }
}

.total-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .total {
    color: #d0adff;
  }

  .plan-price {
    justify-self: end;

    .month-tag {
      color: #c1c8fd;
      font-weight: 400;
      font-size: 1.125rem;
    }
  }
}

.terms {
  a {
    text-decoration: underline;
  }

  p {
    margin-top: 0.5rem;
  }
}

// responsive bits

@media only screen and (min-width: 48.0625rem) {
  .landing-container {
    max-width: 37.5rem;
  }

  .apply-container {
    max-width: 68.75rem;
  }
}

@media only screen and (max-width: 48rem) {
  .card {
    .card-title {
      font-size: 18px;
    }

    img {
      width: 18px;
      height: auto;
    }
  }

  .card-gradient {
    .card-title {
      font-size: 18px;
    }

    img {
      width: 18px;
      height: auto;
    }
  }

  .apply-container {
    padding: 1.875rem 0;
  }

  .landing-container {
    max-width: 28.125rem;
  }

  .apply-container {
    max-width: 27.5rem;
    margin: 0 auto;
  }

  .apply-split {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr max-content;
  }

  .right-column {
    grid-row: 1 / 2;
  }
}

@media only screen and (max-width: 28.0625rem) {
  .apply-container {
    margin: 0 0.625rem;
  }
}

@media only screen and (max-width: 20.0625rem) {
  .apply-container {
    max-width: 18.75rem;
    margin: 0 0.625rem;
  }
}

@media only screen and (max-width: 17.5625rem) {
  .apply-container {
    max-width: 16.875rem;
    margin: 0 0.625rem;
  }
}
