@import "../../assets/styles/config.scss";

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  color: $primary-color;
}

body {
  background: url(../../assets/images/bg.png) no-repeat center center fixed;
  background-color: $background;
  background-size: cover;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

select {
  transition: 0.1s all ease-in;

  &:hover {
    opacity: 0.8;
  }
}

input {
  cursor: pointer;
  transition: 0.1s all ease-in;

  &:hover {
    opacity: 0.8;
  }
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.skeleton {
  background: linear-gradient(0.25turn, transparent, #28233a, transparent);
  background-size: 200% 200%;
  animation: pulse 1s ease-in-out infinite;
  border-radius: 10px;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -100% 0%;
    }
  }

  &::before {
    content: "\00a0";
  }
}

.logo-img {
  width: 8em;
}

.logout-container {
  justify-self: end;
  transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    opacity: 0.8;
  }
}

.logout {
  font-size: 12px;
  display: grid;
  grid-template-columns: min-content max-content;
  grid-gap: 15px;
  color: $secondary-color;
  font-weight: 600;
  padding: 10px 20px;
  background-color: rgba(26, 23, 38, 0.6);
  border-radius: 8px;
  align-items: center;
}

.dash-container {
  max-width: 1234px;
  margin: 0 auto;
  padding: 20px;
  height: 100vh;

  @media only screen and (max-width: 768px) {
    padding: 10px;
    height: 100vh;
  }
}

.nav-wrappers {
  display: grid;
  grid-template-rows: min-content max-content auto max-content;
  grid-gap: 10px;
  height: 100%;
  width: 100%;
}

.nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.header {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.title-section {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
}

span {
  font-size: 14px;
  color: $primary-color;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 20px;
  }

  span {
    font-size: 10px;
  }
}

.profile-img {
  width: 35px;
  height: auto;
}

.ping {
  width: 8px;
  height: 8px;
  background: cyan;
  border-radius: 50%;
}

.user-section {
  width: 200px;
  background-color: rgba(26, 23, 38, 0.6);
  border-radius: 10px;
}

.user-container {
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 10px;
  margin: 10px;
  align-items: center;
}

.username {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 125px;
  display: block;
  overflow: hidden;
  font-size: 14px;
  font-weight: 700;
}

.email {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 125px;
  display: block;
  overflow: hidden;
  font-size: 10px;
  color: $secondary-color;
}

/* DASHBOARD */

.dash-background {
  width: 100%;
  background-color: rgba(27, 24, 38, 0.6);
  border-radius: 10px;
}

.dash-container {
  display: grid;
  grid-template-rows: 75px 1fr;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .dash-container {
    grid-template-rows: 150px 1fr;
  }
}

.dash-header {
  display: grid;
  grid-template-columns: max-content auto min-content;
  grid-gap: 15px;
  align-items: center;
  border-style: solid;
  border-color: #4d436f;
  border-top-width: 0px;
  border-bottom-width: 1.5px;
  border-left-width: 0px;
  border-right-width: 0px;
  padding: 10px 15px;
}

.server-c {
  input {
    display: -webkit-inline-flex;
    grid-gap: 15px;
    color: $primary-color;
    font-weight: 600;
    padding: 10px 20px;
    background: linear-gradient(90deg, rgba(78, 93, 238, 0.3) 0%, rgba(114, 87, 235, 0.3) 46%, rgba(160, 99, 224, 0.3) 100%);
    border: none;
    border-radius: 8px;
    justify-self: end;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    font-size: 0.8rem;
    &:hover {
      color: $primary-color;
      background: linear-gradient(90deg, rgba(78, 93, 238, 1) 0%, rgba(114, 87, 235, 1) 46%, rgba(160, 99, 224, 1) 100%);
    }
  }
}

@media only screen and (max-width: 768px) {
  .dash-header {
    display: grid;
    grid-template-columns: none;
    grid-gap: 2px;
    width: 100%;
  }
}

.dropdown {
  display: grid;
  grid-template-columns: max-content min-content;
  grid-gap: 15px;
  color: $secondary-color;
  font-weight: 600;
  padding: 10px 20px;
  background-color: rgba(52, 46, 75, 0.6);
  border: none;
  border-radius: 8px;
  align-items: center;

  @media (min-width: 768px) {
    width: 13rem;
    max-width: 13rem;
  }

  &:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .dropdown {
    width: 100%;
  }
}

/* Modal */
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 425px;
  background: #1b1826;
  border-radius: 10px;
  outline: none;
  padding: 20px;

  @media only screen and (max-width: 768px) {
    width: 400px;
  }

  #saveInfo {
    height: 2.5rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    letter-spacing: 0.056px;
  }
  .invite {
    padding: 0;
    height: 2.5rem;
    a {
      height: 2.5rem;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      letter-spacing: 0.056px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 17, 28, 0.6);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.open-modal {
  display: -webkit-inline-flex;
  grid-gap: 15px;
  color: $primary-color;
  font-weight: 600;
  padding: 10px 20px;
  background: linear-gradient(90deg, rgba(78, 93, 238, 0.3) 0%, rgba(114, 87, 235, 0.3) 46%, rgba(160, 99, 224, 0.3) 100%);
  border: none;
  border-radius: 8px;
  justify-self: end;
  transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    color: $primary-color;
    background: linear-gradient(90deg, rgba(78, 93, 238, 1) 0%, rgba(114, 87, 235, 1) 46%, rgba(160, 99, 224, 1) 100%);
    cursor: pointer;
  }
}

.input-desc {
  font-size: 0.9rem;
  color: #fff;
  opacity: 0.8;
}

.robot-icon {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .open-modal {
    width: 100%;
    grid-template-columns: max-content max-content;
    text-align: center;
  }
}

.modal-title {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.top-title {
  font-weight: 600;
  font-size: 20px;
}

.bottom-title {
  font-weight: 600;
  font-size: 24px;
}

.modal-content {
  display: grid;
  grid-template-rows: repeat(7, min-content);
  grid-gap: 10px;
  padding-top: 20px;
  form {
    height: 100%;
  }
}

.tip-container {
  display: block;
  margin: auto;
  width: 100%;
  text-align: center;
  background-color: #171420;
  border-radius: 10px;
  padding: 12px;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 500;
}

.btn {
  display: block;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border-radius: 10px;
  font-weight: 600;
  transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    opacity: 0.8;
  }
}

.save {
  width: 100%;
  background: linear-gradient(180deg, #70ff92 0%, hsl(139, 53%, 44%) 100%);
  padding: 12px;
  transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    opacity: 0.8;
  }
}

.invite {
  background: linear-gradient(180deg, #4da9ff 0%, hsl(230, 94%, 65%) 100%);
  padding: 12px;
  height: 2.5rem;
}

/* Footer */
.footer {
  display: grid;
  grid-template-columns: max-content 1fr 1fr;
  width: 100%;
  align-items: center;
}

.activated {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 10px;
  font-size: 12px;
  color: $secondary-color;
  background-color: rgba(46, 42, 62, 0.6);
  border-radius: 5px;
  padding: 10px;
  align-items: center;
}

.footer-text {
  text-align: right;
  color: $secondary-color;
}

.footer-links {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 15px;
  justify-self: end;

  a {
    color: $secondary-color;
    font-size: 12px;
    background-color: rgba(46, 42, 62, 1);
    border-radius: 5px;
    padding: 10px;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      opacity: 0.8;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 10px;
  }

  .footer-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    justify-self: auto;
    text-align: center;
  }
  .activated {
    width: 100%;
    margin: 0 auto;
    grid-column: 1 / 3;
  }

  .footer-text {
    grid-column: 1 / 3;
    text-align: center;
    padding-bottom: 10px;
  }
}

/* INPUT FIELDS */
.input-modal {
  display: block;
  margin: auto;
  width: 100%;
  text-align: center;
  background-color: #1f1c2b;
  border: 0.5px solid #282537;
  border-radius: 10px;
  padding: 12px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

::placeholder {
  color: $secondary-color;
}

button:active {
  outline: none;
  border: none;
}

select {
  -webkit-appearance: none;
}

::-webkit-scrollbar {

  background: rgba(27, 24, 38, .3);
}
::-webkit-scrollbar-thumb {
  background: rgba(27, 24, 38, 1);
}