@import "../../assets/styles/config.scss";

.general-container {
  margin: 20px 0;
  padding: 0 40px;
}

.general-background {
  width: 500px;
  height: 1050px;
  background-color: #161321;
  margin: 5px auto;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .general-background {
    width: 100%;
  }
}

.setting-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 10px;
  text-align: center;
}

.settings-wrapper {
  display: flex;
  flex-direction: column;
}

.settings-wrapper > input {
  text-align: left;
}

.save-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    @media (min-width: 30em) {
      width: 50%;
      font-size: 1rem;
      height: 2.5rem;
    }

    @media (max-width: 30em) {
      font-size: 0.9rem;
      width: 100%;
      height: 2.5rem;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.056px;
  }
}
