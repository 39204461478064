.sub-title {
  padding: 20px 0 10px 0;
  color: #9094ff;
  font-size: 16px;
  font-weight: 700;
}
.cookie-btn {
  font-size: 14px;
  font-weight: 600;
}
.terms-wrapper {
  min-height: 100vh;
  padding: 3rem 0;
}
.terms-content {
  padding-top: 20px;
}